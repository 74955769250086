import { useContext, useRef } from "react";
import { Form, Flex, Button, Row, Col } from "antd";

import {
  NotificationContext,
  AddIcon,
  CloseIcon,
  useTheme,
  commonStyles,
} from "@datwyler/mfe-shared-components";
import { checkSiteHasDevices } from "@helpers";
import { SiteFormType } from "@types";

const {
  InputField,
  Body16RegularPrimary,
  Button14Brand,
  Body14RegularSecondary,
} = commonStyles;

const SiteForm = ({ form, devices, intl }: SiteFormType) => {
  const containerRef = useRef<HTMLDivElement>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { openNotification } = useContext<any>(NotificationContext);
  const theme = useTheme();
  const canRemoveSite = (remove, name) => {
    const site = form.getFieldValue(["sites", name]);
    const siteHasDevice = checkSiteHasDevices(site ? [site] : [], devices);
    if (siteHasDevice) {
      openNotification({
        type: "error",
        description: intl.formatMessage({ id: "del_site_validation_msg" }),
        placement: "topRight",
      });
    } else {
      remove(name);
    }
  };
  const scrollToView = () => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollIntoView();
      }
    }, 100);
  };

  return (
    <Form.List name="sites">
      {(fields, { add, remove }) => {
        return (
          <>
            <Flex vertical gap={16}>
              <Flex
                justify="space-between"
                align="center"
                style={{ width: "100%" }}
              >
                <div>
                  <Body16RegularPrimary>
                    {intl.formatMessage({ id: "site" })}
                  </Body16RegularPrimary>
                </div>
                <Button14Brand
                  onClick={() => {
                    add();
                    scrollToView();
                  }}
                  type="text"
                  icon={
                    <AddIcon
                      style={{
                        height: 24,
                        width: 24,
                        color: theme.datwylerIcon.iconBrand,
                      }}
                    />
                  }
                >
                  {intl.formatMessage({ id: "add_site" })}
                </Button14Brand>
              </Flex>
            </Flex>

            {fields.map(({ key, name, ...restField }) => {
              return (
                <Row
                  ref={containerRef}
                  key={key}
                  justify="space-between"
                  style={{ marginTop: 8 }}
                >
                  <Col span={6}>
                    <Body14RegularSecondary>
                      {intl.formatMessage({ id: "site" })}
                    </Body14RegularSecondary>
                  </Col>
                  <Col span={6} style={{ textAlign: "right" }}>
                    <Button
                      onClick={() => canRemoveSite(remove, name)}
                      type="text"
                      icon={<CloseIcon style={{ height: 20, width: 20 }} />}
                    />
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "name"]}
                      label={intl.formatMessage({ id: "add_site_label" })}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: "add_site_validation_msg",
                          }),
                        },
                      ]}
                    >
                      <InputField
                        placeholder={intl.formatMessage({
                          id: "add_site_placeholder",
                        })}
                        variant="filled"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "url"]}
                      label={intl.formatMessage({ id: "url" })}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: "url_validation_msg",
                          }),
                        },
                        { type: "url", message: "Invalid" },
                      ]}
                    >
                      <InputField
                        placeholder={intl.formatMessage({
                          id: "url_placeholder",
                        })}
                        variant="filled"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              );
            })}
          </>
        );
      }}
    </Form.List>
  );
};

export default SiteForm;
