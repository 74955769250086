import { useState, useEffect } from "react";
import {
  lodash as _,
  useAttachment,
  useLocation,
} from "@datwyler/mfe-shared-components";
import { transformFilters } from "@helpers";
import { EntityStatus } from "@types";

const filterDefaults = {
  status: [EntityStatus.ACTIVE],
};
const useAttachments = (tenantId: string) => {
  const [tenantID, setTenantID] = useState(tenantId);
  const {
    fetchAttachments,
    fetchAttachmentsData,
    updateAttachment,
    updateAttachmentResponseData,
  } = useAttachment();
  const { fetchLocationData, fetchLocations } = useLocation();
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    setTenantID(tenantId);
  }, [tenantId, setTenantID]);

  useEffect(() => {
    fetchLocations({
      variables: { tenantId: tenantID },
    });
  }, [fetchLocations, tenantID]);

  useEffect(() => {
    fetchAttachments({
      variables: {
        tenantId: tenantID,
        page: { number: pageNumber - 1, size: rowsPerPage * 1 },
        sort: ["createdOn,desc"],
        filter: transformFilters({ ...filterDefaults, ...filters }),
      },
    });
  }, [
    fetchAttachments,
    tenantID,
    pageNumber,
    rowsPerPage,
    filters,
    updateAttachmentResponseData,
  ]);

  const deleteAttachment = (attachment) => {
    const attachmentPayload = {
      id: attachment.raw.id,
      name: attachment.attachment_name,
      description: attachment.description,
      status: EntityStatus.DEACTIVATED,
      tenant: { id: tenantId },
    };
    updateAttachment({
      variables: { input: attachmentPayload },
    });
  };

  return {
    attachments: _.get(fetchAttachmentsData, "attachments.attachments", []),
    locations: _.get(fetchLocationData, "locations", []),
    totalRows: _.get(fetchAttachmentsData, "attachments.page.totalElements", 0),
    deletedAttachment: _.get(
      updateAttachmentResponseData,
      "updateAttachment.attachment",
      null
    ),
    pageNumber,
    rowsPerPage,
    setPageNumber,
    setRowsPerPage,
    setFilters,
    deleteAttachment,
  };
};

export default useAttachments;
