import { Space, Form } from "antd";

import {
  BasicModal,
  SingleSelect,
  Dragger,
  commonStyles,
} from "@datwyler/mfe-shared-components";
import { allowedExtensions } from "@helpers";
import { AttachmentModalType } from "@types";
import { CancelButton } from "./styled";

const { InputField, Button16Invert } = commonStyles;

const Modal = ({
  isOpen,
  setIsOpen,
  form,
  submittable,
  handleSubmit,
  filelist,
  setFilelist,
  showSiteField,
  siteId,
  siteOptions,
  intl,
}: AttachmentModalType) => {
  const validateFile = async () => {
    if (filelist.length === 0) {
      return Promise.reject(
        new Error(intl.formatMessage({ id: "file_required" }))
      );
    }
    const file = filelist[0]; // As we are allowing only one file
    const extension = file.name.replace(/.*\./, ".").toLowerCase();
    const isSizeValid = file.size < 1024 * 1024 * 100; // Example validation: size < 100MB
    const isValidExtension = allowedExtensions.includes(extension);

    if (!isValidExtension) {
      setFileError(file);
      return Promise.reject(
        new Error(intl.formatMessage({ id: "invalid_file_format" }))
      );
    }

    if (!isSizeValid) {
      setFileError(file);
      return Promise.reject(
        new Error(intl.formatMessage({ id: "file_size_validation" }))
      );
    }

    return Promise.resolve();
  };

  const setFileError = (file) => {
    const newFile = Object.assign({ status: "error" }, file);
    setFilelist([newFile]);
  };

  const handleCancel = () => {
    setFilelist([]);
    setIsOpen(false);
  };
  return (
    <>
      <BasicModal
        title="Add Attachment"
        isOpen={isOpen}
        handleCancel={handleCancel}
        footer={() => {
          return (
            <Space>
              <CancelButton onClick={handleCancel}>
                {intl.formatMessage({ id: "cancel_btn" })}
              </CancelButton>
              <Button16Invert
                htmlType="submit"
                disabled={!submittable}
                type="primary"
              >
                {intl.formatMessage({ id: "upload_btn" })}
              </Button16Invert>
            </Space>
          );
        }}
        modalRender={(dom) => (
          <Form
            layout="vertical"
            form={form}
            name="form_in_modal"
            initialValues={{ modifier: "public" }}
            clearOnDestroy
            onFinish={handleSubmit}
          >
            {dom}
          </Form>
        )}
      >
        <Space direction="vertical" style={{ display: "flex" }}>
          <Form.Item
            name="siteId"
            label={intl.formatMessage({ id: "site" })}
            hidden={!showSiteField}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "site_validation_msg" }),
              },
            ]}
            initialValue={siteId}
          >
            <SingleSelect
              style={{ width: "100%", height: "56px" }}
              placeholder={intl.formatMessage({ id: "site_placeholder" })}
              popupMatchSelectWidth="100%"
              options={siteOptions}
              variant="filled"
            />
          </Form.Item>
          <Form.Item
            name="name"
            label={intl.formatMessage({ id: "name" })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "name_validation_msg" }),
              },
            ]}
          >
            <InputField
              placeholder={intl.formatMessage({ id: "name_placeholder" })}
              variant="filled"
            />
          </Form.Item>
          <Form.Item
            name="description"
            label={intl.formatMessage({ id: "description" })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: "description_validation_msg",
                }),
              },
            ]}
          >
            <InputField
              placeholder={intl.formatMessage({
                id: "description_placeholder",
              })}
              variant="filled"
            />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            <Form.Item
              name="dragger"
              rules={[{ validator: validateFile }]}
              valuePropName="filelist"
              validateTrigger={["onBlur", "onChange"]}
              getValueFromEvent={({ file }) => {
                return [file];
              }}
            >
              <Dragger
                allowedExtensions={allowedExtensions}
                filelist={filelist}
                setFilelist={setFilelist}
              />
            </Form.Item>
          </Form.Item>
        </Space>
      </BasicModal>
    </>
  );
};

export default Modal;
