import { Row, Col } from "antd";
import { commonStyles } from "@datwyler/mfe-shared-components";
import { AlarmContainer } from "./styled";

const { Body14RegularTertiary, Body14RegularPrimary } = commonStyles;

const Details = ({
  content,
}: {
  content: { label: string; value: string }[];
}) => {
  return (
    <>
      {content.map(({ label, value }) => (
        <AlarmContainer key={label}>
          <Row justify="space-between">
            <Col
              span={11}
              style={{ textAlign: "left", overflowWrap: "break-word" }}
            >
              <Body14RegularTertiary>{label}</Body14RegularTertiary>
            </Col>
            <Col
              span={11}
              style={{ textAlign: "right", overflowWrap: "break-word" }}
            >
              <Body14RegularPrimary>{value}</Body14RegularPrimary>
            </Col>
          </Row>
        </AlarmContainer>
      ))}
    </>
  );
};

export default Details;
