import { styled } from "@datwyler/mfe-shared-components";
import { Row } from "antd";

export const StyledRow = styled(Row)`
  padding: 16px 16px 16px 32px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.theme.datwylerSurface.surfaceSecondary};
  }
`;

export const HeaderContainer = styled(Row)`
  padding: 16px;
  height: 116px;
  border-bottom: 1px solid
    ${(props) => props.theme.datwylerBorder.borderPrimary};
  gap: 8px;
`;
