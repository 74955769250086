import { Flex, Button } from "antd";
import type { TabsProps } from "antd";

import {
  SingleArrowLeftIcon,
  commonStyles,
} from "@datwyler/mfe-shared-components";
import Attachment from "@components/SiteDetails/Attachment";
import Details from "./Details";
import Status from "./Status";
import Alarm from "./Alarm";
import { translateDetailsTab } from "@helpers";
import { SiteHeaderType, SiteDetailsTab, SiteDetailsType } from "@types";
import { StyledTabs } from "./styled";

const { StyledCard2: StyledCard, Title18MediumPrimary } = commonStyles;

const Header = ({
  site,
  setSelectedSite,
  setComponentToShow,
}: SiteHeaderType) => {
  return (
    <Flex
      style={{ padding: "16px 8px 8px 8px" }}
      align="center"
      justify="space-between"
    >
      <Flex align="center">
        <Button
          onClick={() => {
            setSelectedSite(null);
            setComponentToShow("site-list");
          }}
          type="text"
          icon={<SingleArrowLeftIcon style={{ height: 24, width: 24 }} />}
        />

        <Title18MediumPrimary>{site?.name}</Title18MediumPrimary>
      </Flex>
      {/* <ArrowUp style={{ height: 24, width: 24 }} /> */}
    </Flex>
  );
};

const Tabs = ({ siteDetails, alarms, tenantId, intl }: SiteDetailsTab) => {
  const { location, site, devices } = siteDetails;
  const detailsTabContent = translateDetailsTab(location, site, devices);
  const items: TabsProps["items"] = [
    {
      key: "details",
      label: intl.formatMessage({ id: "details" }),
      children: <Details content={detailsTabContent} />,
    },
    {
      key: "status",
      label: intl.formatMessage({ id: "status" }),
      children: <Status intl={intl} devices={devices} />,
    },
    {
      key: "alert",
      label: intl.formatMessage({ id: "alert" }),
      children: <Alarm intl={intl} alarms={alarms} />,
    },
    {
      key: "attachments",
      label: intl.formatMessage({ id: "attachments" }),
      children: <Attachment tenantId={tenantId} siteId={site.id} />,
    },
  ];
  return (
    <StyledTabs
      indicator={{ size: () => 0 }}
      defaultActiveKey="1"
      items={items}
    />
  );
};

const SiteDetails = ({
  siteDetails,
  setSelectedSite,
  alarmDetails,
  tenantId,
  setComponentToShow,
  intl,
}: SiteDetailsType) => {
  const { site } = siteDetails;
  return (
    <StyledCard style={{ height: "500px" }}>
      <Header
        site={site}
        setSelectedSite={setSelectedSite}
        setComponentToShow={setComponentToShow}
      />
      <Tabs
        siteDetails={siteDetails}
        alarms={alarmDetails}
        tenantId={tenantId}
        intl={intl}
      />
    </StyledCard>
  );
};

export default SiteDetails;
