import { IntlShape } from "react-intl";

import type {
  MappingAlgorithm,
  FormInstance,
  FormProps,
  UploadFile,
} from "antd";
import type { AliasToken, OverrideToken } from "antd/es/theme/interface";

export enum EntityStatus {
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED",
}

export enum Severity {
  CRITICAL = "CRITICAL",
  WARNING = "WARNING",
  INFORMATION = "INFORMATION",
}

export enum AlarmStatus {
  ACTIVE = "ACTIVE",
  IN_PROGRESS = "IN_PROGRESS",
  ESCALATED = "ESCALATED",
  RESOLVED = "RESOLVED",
  DEACTIVATED = "DEACTIVATED",
}
interface ThemeConfig<T = unknown> {
  token?: Partial<AliasToken> & T;
  components?: OverrideToken;
  algorithm?: MappingAlgorithm | MappingAlgorithm[];
  hashed?: boolean;
  inherit?: boolean;
}

export interface CustomThemePropType {
  datwylerBrand: Record<string, string>;
  datwylerText: Record<string, string>;
  datwylerBorder: Record<string, string>;
  datwylerIcon: Record<string, string>;
  datwylerSurface: Record<string, string>;
}

export type CustomThemeConfigProps = ThemeConfig<CustomThemePropType>;

export interface ThemeContextType {
  isDarkMode: boolean;
  isDarkModeAuto: boolean;
  theme: CustomThemeConfigProps;
}

export type TokenType = Partial<AliasToken> & CustomThemePropType;

export type FieldType = {
  name: string;
  description: string;
  dragger: File;
  siteId?: string;
};

export type DeviceType = {
  name: string;
  id: string;
  telemetry: { name: string; value: string }[];
};

export interface AttachmentType {
  siteId: string;
  tenantId: string;
  showSiteField?: boolean;
  siteOptions?: { label: string; value: string }[];
  onChildUpdate?: () => void;
}

export interface AttachmentModalType {
  form: FormInstance;
  isOpen: boolean;
  submittable: boolean;
  showSiteField: boolean;
  siteId: string;
  filelist: UploadFile[];
  siteOptions: { label: string; value: string }[];
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (props: FormProps<FieldType>) => void;
  setFilelist: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  intl: IntlShape;
}

export interface AttachmentFilterType {
  setFilters: React.Dispatch<
    React.SetStateAction<{
      name?: string;
      locations?: [string];
      filename?: [string];
      siteId?: [string];
    }>
  >;
  locations: { name: string; id: string }[];
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  intl: IntlShape;
}

export interface AttachmentTableType {
  attachments: { site: { id: string; name: string } }[];
  locations: { sites: { id: string }[] }[];
  totalRows: number;
  rowsPerPage: number;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  handleAttachmentDownload: (attachment) => void;
  deleteAttachment: (attachment) => void;
  intl: IntlShape;
}

export interface DeleteModalType {
  title: string;
  okText: string;
  cancelText: string;
  content: string;
  open: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

export interface InfoModalType {
  open: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  infoData: { [key: string]: string | number };
  intl: IntlShape;
}

export interface LocationCompType {
  tenantId: string;
  setComponentToShow: (name: string) => void;
  location?: LocationType;
  mode: "add" | "edit";
}

export interface LocationHeaderType {
  setComponentToShow: (name: string) => void;
  mode: "add" | "edit";
  intl: IntlShape;
}

export interface LocationFooterType {
  mode: "add" | "edit";
  setShowDeleteConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  intl: IntlShape;
}

export interface LocationFormType {
  countryOptions: { label: string; value: string }[];
  intl: IntlShape;
}

export interface SiteFormType {
  devices: { site: { id: string }[] }[];
  form: FormInstance;
  intl: IntlShape;
}

export interface ListItemType {
  location: LocationType;
  setSelectedLocation: React.Dispatch<React.SetStateAction<LocationType>>;
  setComponentToShow: (name: string) => void;
}

export interface LocationListHeader {
  countryOptions: { label: string; value: string }[];
  locationFilter: string;
  setLocationFilter: React.Dispatch<React.SetStateAction<string>>;
  setComponentToShow: (name: string) => void;
  intl: IntlShape;
}

export interface SiteDetailsType {
  siteDetails: {
    location: LocationType;
    devices: [];
    site: SiteType;
  };
  setSelectedSite: React.Dispatch<React.SetStateAction<SiteType>>;
  alarmDetails: AlarmType[];
  tenantId: string;
  setComponentToShow: (name: string) => void;
  intl: IntlShape;
}

export interface SiteDetailsTab {
  siteDetails: {
    location: LocationType;
    devices: [];
    site: SiteType;
  };
  alarms: AlarmType[];
  tenantId: string;
  intl: IntlShape;
}

export interface SiteHeaderType {
  site: SiteType;
  setSelectedSite: React.Dispatch<React.SetStateAction<SiteType>>;
  setComponentToShow: (name: string) => void;
}

export interface SiteListType {
  location: LocationType;
  setSelectedSite: React.Dispatch<React.SetStateAction<SiteType>>;
  setComponentToShow: (name: string) => void;
  intl: IntlShape;
}

export interface SiteListItem {
  site: SiteType;
  setSelectedSite: React.Dispatch<React.SetStateAction<SiteType>>;
  setComponentToShow: (name: string) => void;
}

export interface SiteListHeader extends LocationType {
  setComponentToShow: (name: string) => void;
  setSelectedSite: React.Dispatch<React.SetStateAction<SiteType>>;
}

export type AlarmType = {
  device: {
    id: string;
    name: string;
  };
  severity: Severity;
  time: number;
};

export type SiteType = {
  id: string;
  name: string;
  status: EntityStatus;
  url: string;
};

export type LocationType = {
  sites: SiteType[];
  name: string;
  longitude: string;
  latitude: string;
  country: { id: string; name: string };
  id: string;
  intl: IntlShape;
};
export interface LocationsProps {
  tenantId: string;
}

export interface LocationsListProps {
  locations: LocationType[];
  locationFilter: string;
  setLocationFilter: React.Dispatch<React.SetStateAction<string>>;
  setSelectedLocation: React.Dispatch<React.SetStateAction<LocationType>>;
  setComponentToShow: (name: string) => void;
  intl: IntlShape;
}
