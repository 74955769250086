import { useContext } from "react";
import { useLocation, Route, Routes } from "react-router-dom";
import { ConfigProvider } from "antd";
import {
  lodash as _,
  DarkModeContext,
  UserProvider,
  TenantIdProvider,
  PageNotFound,
} from "@datwyler/mfe-shared-components";

import Locations from "@containers/locations";
import Attachments from "@containers/attachments";
import { ThemeContextType } from "@types";

const App = ({ SECURED_GATEWAY_URL }: { SECURED_GATEWAY_URL: string }) => {
  const { theme } = useContext<ThemeContextType>(DarkModeContext);
  const location = useLocation();

  const tenantId = _.get(location, "state.tenantId");

  return (
    <UserProvider userProviderUri={SECURED_GATEWAY_URL}>
      <TenantIdProvider tenantId={tenantId}>
        <ConfigProvider theme={theme}>
          <Routes>
            <Route
              path="/site/attachments"
              element={<Attachments tenantId={tenantId} />}
            />
            <Route path="/site" element={<Locations tenantId={tenantId} />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </ConfigProvider>
      </TenantIdProvider>
    </UserProvider>
  );
};

export default App;
