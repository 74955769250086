import { styled } from "@datwyler/mfe-shared-components";
import { Tabs } from "antd";

export const StyledTabs = styled(Tabs)`
  padding: 16px 16px 0 16px;
  .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-nav-list {
    width: 100%;
    justify-content: space-between;
  }
  .ant-tabs-tab {
    margin: 0px !important;
    padding: 8px;
    .ant-tabs-tab-btn {
      color: ${(props) => props.theme.datwylerText.textTertiary};
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    background-color: ${(props) => props.theme.datwylerSurface.surfaceBrand3};
    border-radius: 4px;
    .ant-tabs-tab-btn {
      color: ${(props) => props.theme.datwylerText.textInvert};
    }
  }
`;

export const AlarmContainer = styled.div`
  padding: 16px 0px;
  border-bottom: 1px solid
    ${(props) => props.theme.datwylerBorder.borderPrimary};
  &:last-child {
    border: 0;
  }
`;
