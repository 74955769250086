import { BasicModal } from "@datwyler/mfe-shared-components";
import { DeleteModalType } from "@types";

const DeleteConfirmModal = ({
  title,
  okText,
  cancelText,
  content,
  open,
  handleOk,
  handleCancel,
}: DeleteModalType) => {
  return (
    <BasicModal
      title={title}
      isOpen={open}
      okText={okText}
      okType="danger"
      okButtonProps={{
        type: "primary",
        size: "large",
      }}
      cancelButtonProps={{
        size: "large",
      }}
      cancelText={cancelText}
      handleCancel={handleCancel}
      handleOk={handleOk}
    >
      {content}
    </BasicModal>
  );
};

export default DeleteConfirmModal;
