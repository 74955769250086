import { useEffect, useState } from "react";
import {
  BasicDataTable,
  MoreButton,
  lodash as _,
  moment,
} from "@datwyler/mfe-shared-components";
import InformationModal from "@components/InformationModal";
import DeleteConfirmModal from "@components/DeleteConfirmModal";
import { AttachmentTableType } from "@types";

const AttachmentTable = ({
  attachments,
  locations,
  totalRows,
  rowsPerPage,
  pageNumber,
  setPageNumber,
  setRowsPerPage,
  handleAttachmentDownload,
  deleteAttachment,
  intl,
}: AttachmentTableType) => {
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    const tempData = [];
    attachments.forEach((attachment) => {
      const siteId = _.get(attachment, "site.id", "");
      const location = locations.find((location) => {
        const sites = _.get(location, "sites", []) || [];
        return sites.some((site) => site.id === siteId);
      });
      const format = _.get(attachment, "filename", "").split(".").pop();
      tempData.push({
        location: _.get(location, "name", ""),
        site: _.get(attachment, "site.name", ""),
        attachment_name: _.get(attachment, "name", ""),
        format,
        date: moment(_.get(attachment, "createdOn")).format("DD/MM/YYYY HH:mm"),
        description: _.get(attachment, "description", ""),
        createdBy:
          _.get(attachment, "createdBy.firstName", "") +
          " " +
          _.get(attachment, "createdBy.lastName", ""),
        createdOn: moment(_.get(attachment, "createdOn")).format(
          "DD/MM/YYYY HH:mm"
        ),
        raw: {
          fileName: _.get(attachment, "filename", ""),
          siteId: _.get(attachment, "site.id", ""),
          id: _.get(attachment, "id", ""),
        },
      });
    });
    setData(tempData);
  }, [attachments, locations]);

  const handleDetailsClick = (record) => {
    setSelectedRow(record);
    setShowInfoModal(true);
  };

  const handleDeleteAttachment = (record) => {
    setSelectedRow(record);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    deleteAttachment(selectedRow);
    setShowDeleteModal(false);
  };
  const columns = [
    {
      title: intl.formatMessage({ id: "location" }),
      key: "location",
      dataIndex: "location",
    },
    {
      title: intl.formatMessage({ id: "site" }),
      key: "site",
      dataIndex: "site",
    },
    {
      title: intl.formatMessage({ id: "name" }),
      key: "attachment_name",
      dataIndex: "attachment_name",
    },
    {
      title: intl.formatMessage({ id: "format" }),
      key: "format",
      dataIndex: "format",
    },
    {
      title: intl.formatMessage({ id: "date" }),
      key: "date",
      dataIndex: "date",
    },
    {
      dataIndex: "action",
      render: (_, record) => {
        return (
          <MoreButton
            items={[
              {
                label: intl.formatMessage({ id: "details" }),
                onClick: () => handleDetailsClick(record),
              },
              {
                label: intl.formatMessage({ id: "download" }),
                onClick: () => handleAttachmentDownload(record),
              },
              {
                label: intl.formatMessage({ id: "delete" }),
                onClick: () => handleDeleteAttachment(record),
              },
            ]}
          />
        );
      },
    },
  ];

  return (
    <>
      <BasicDataTable
        data={data}
        columns={columns}
        total={totalRows}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        onChangePage={(pageNumber) => {
          setPageNumber(pageNumber);
        }}
        onChangeRowsPerPage={(rowsPerPage) => {
          setRowsPerPage(rowsPerPage);
        }}
        rowKey={(record) => {
          return record.raw.id;
        }}
      />
      <InformationModal
        open={showInfoModal}
        handleOk={() => {}}
        handleCancel={() => setShowInfoModal(false)}
        infoData={_.omit(selectedRow, "raw")}
        intl={intl}
      />
      <DeleteConfirmModal
        title={intl.formatMessage({ id: "delete_attachment_title" })}
        okText={intl.formatMessage({ id: "delete_btn" })}
        cancelText={intl.formatMessage({ id: "cancel_btn" })}
        content={intl.formatMessage({ id: "delete_attachment_text" })}
        open={showDeleteModal}
        handleOk={handleConfirmDelete}
        handleCancel={() => setShowDeleteModal(false)}
      />
    </>
  );
};

export default AttachmentTable;
