/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useContext } from "react";
import { Flex, Button, Typography, Row, Col } from "antd";
import {
  lodash as _,
  moment,
  DownloadIcon,
  TrashIcon,
  useAttachment,
  ApiContext,
  NotificationContext,
  useIntl,
  useTheme,
  commonStyles,
} from "@datwyler/mfe-shared-components";
import Attachment from "@components/Attachment";
import DeleteConfirmModal from "@components/DeleteConfirmModal";
import { EntityStatus } from "@types";
import { downloadAttachment } from "@helpers";
import { AlarmContainer } from "./styled";

const { Body14RegularTertiary, Body14RegularPrimary } = commonStyles;
const { Text } = Typography;

const AttachmentDetails = ({
  tenantId,
  siteId,
}: {
  tenantId: string;
  siteId: string;
}) => {
  const { uri, apiInstance } = useContext<any>(ApiContext);
  const { openNotification } = useContext<any>(NotificationContext);
  const [childUpdate, setChildUpdate] = useState(0);
  const intl = useIntl();
  const theme = useTheme();
  const {
    fetchAttachmentsData,
    fetchAttachments,
    updateAttachment,
    updateAttachmentResponseData,
  } = useAttachment();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState<{
    id: string;
    name: string;
    description: string;
  }>();

  useEffect(() => {
    fetchAttachments({
      variables: {
        tenantId: tenantId,
        page: { number: 0, size: 999999 },
        sort: ["createdOn,desc"],
        filter: [`siteId:${siteId}`, `status:` + EntityStatus.ACTIVE],
      },
    });
  }, [
    fetchAttachments,
    siteId,
    tenantId,
    updateAttachmentResponseData,
    childUpdate,
  ]);

  useEffect(() => {
    const deletedAttachment = _.get(
      updateAttachmentResponseData,
      "updateAttachment.attachment",
      null
    );
    if (deletedAttachment) {
      openNotification({
        type: "success",
        description: intl.formatMessage({ id: "attachment_deleted_success" }),
        placement: "topRight",
      });
    }
  }, [intl, updateAttachmentResponseData, openNotification]);

  const attachmentData = _.get(
    fetchAttachmentsData,
    "attachments.attachments",
    []
  );

  const handleDownload = (attachment) => {
    const {
      id,
      site: { id: siteId },
      filename,
    } = attachment;
    downloadAttachment(
      {
        id,
        siteId,
        fileName: filename,
      },
      tenantId,
      uri,
      apiInstance,
      openNotification
    );
  };

  const handleConfirmDelete = () => {
    if (selectedAttachment) {
      const attachmentPayload = {
        id: selectedAttachment.id,
        name: selectedAttachment.name,
        description: selectedAttachment.description,
        status: EntityStatus.DEACTIVATED,
        tenant: { id: tenantId },
      };
      updateAttachment({
        variables: { input: attachmentPayload },
      });
      setShowDeleteModal(false);
    }
  };

  return (
    <Flex style={{ paddingBottom: 16 }} vertical>
      {attachmentData.map((attachment) => {
        const { id, name, filename, createdOn } = attachment;
        const fileExtension = filename.split(".").pop();
        const date = moment(createdOn).format("DD/MM/YYYY HH:mm");
        return (
          <AlarmContainer key={id}>
            <Row align={"middle"}>
              <Col span={5}>
                <Body14RegularPrimary>
                  <Text ellipsis={{ tooltip: name }}>{name}</Text>
                </Body14RegularPrimary>
              </Col>
              <Col span={3} style={{ textAlign: "center" }}>
                <Body14RegularTertiary>{fileExtension}</Body14RegularTertiary>
              </Col>
              <Col span={10} style={{ textAlign: "center" }}>
                <Body14RegularTertiary>{date}</Body14RegularTertiary>
              </Col>
              <Col span={3}>
                <Button
                  shape="circle"
                  icon={<DownloadIcon />}
                  onClick={() => handleDownload(attachment)}
                />
              </Col>
              <Col span={3}>
                <Button
                  type="primary"
                  shape="circle"
                  icon={
                    <TrashIcon
                      style={{ color: theme.datwylerIcon.iconInvert }}
                      onClick={() => {
                        setSelectedAttachment(attachment);
                        setShowDeleteModal(true);
                      }}
                    />
                  }
                />
              </Col>
            </Row>
          </AlarmContainer>
        );
      })}
      <Attachment
        siteId={siteId}
        tenantId={tenantId}
        onChildUpdate={() => setChildUpdate((prev) => prev + 1)}
      />
      <DeleteConfirmModal
        title={intl.formatMessage({ id: "delete" })}
        okText={intl.formatMessage({ id: "delete_btn" })}
        cancelText={intl.formatMessage({ id: "cancel_btn" })}
        content={intl.formatMessage({ id: "delete_location_text" })}
        open={showDeleteModal}
        handleOk={handleConfirmDelete}
        handleCancel={() => setShowDeleteModal(false)}
      />
    </Flex>
  );
};

export default AttachmentDetails;
