import { useState, useEffect, useCallback } from "react";
import {
  useLocation,
  useDevice,
  useAlarm,
  lodash as _,
} from "@datwyler/mfe-shared-components";
import { EntityStatus, AlarmStatus } from "@types";
const useLocations = (tenantId: string) => {
  const [tenantID, setTenantID] = useState(tenantId);
  const { fetchLocationData, fetchLocations } = useLocation();
  const { fetchDeviceData, fetchDevices } = useDevice();
  const { fetchAlarmData, fetchAlarms } = useAlarm();

  useEffect(() => {
    setTenantID(tenantId);
  }, [tenantId, setTenantID]);

  useEffect(() => {
    fetchLocations({
      variables: { tenantId: tenantID },
    });
  }, [fetchLocations, tenantID]);

  const getSiteDetails = useCallback(
    (siteId: string) => {
      fetchDevices({
        variables: {
          tenantId: tenantID,
          page: { number: 0, size: 999999 },
          filter: [`status:${EntityStatus.ACTIVE},siteId:${siteId}`],
        },
      });
    },
    [fetchDevices, tenantID]
  );

  const getAlarmDetails = useCallback(
    (siteId: string) => {
      fetchAlarms({
        variables: {
          tenantId: tenantID,
          filter: {
            site: { id: siteId },
            status: [
              AlarmStatus.ACTIVE,
              AlarmStatus.IN_PROGRESS,
              AlarmStatus.ESCALATED,
            ],
          },
          page: { number: 0, size: 999999 },
          sort: ["deviceId"],
        },
      });
    },
    [fetchAlarms, tenantID]
  );

  return {
    locations: _.get(fetchLocationData, "locations", []),
    siteDetails: _.get(fetchDeviceData, "devices.devices", []),
    alarmDetails: _.get(fetchAlarmData, "alarms.alarms", []),
    getSiteDetails,
    getAlarmDetails,
  };
};

export default useLocations;
