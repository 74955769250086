import { Flex, Row, Col, Typography } from "antd";
import { IntlShape } from "react-intl";
import { commonStyles } from "@datwyler/mfe-shared-components";
import { type DeviceType } from "@types";
import { AlarmContainer } from "./styled";

const { Body16MediumPrimary, Body14RegularTertiary, Body14RegularPrimary } =
  commonStyles;
const { Text } = Typography;

const Status = ({
  devices,
  intl,
}: {
  devices: DeviceType[];
  intl: IntlShape;
}) => {
  return (
    <>
      {devices.map(({ name, id, telemetry }) => {
        return (
          <AlarmContainer key={id}>
            <Flex vertical gap={18}>
              <div>
                <Body16MediumPrimary>{name}</Body16MediumPrimary>
              </div>
              {telemetry.map(({ name, value }, index) => {
                const i18nName = intl.formatMessage({ id: name });
                return (
                  <Row key={index} justify="space-between">
                    <Col
                      span={14}
                      style={{ textAlign: "left", overflowWrap: "break-word" }}
                    >
                      <Body14RegularTertiary>
                        <Text ellipsis={{ tooltip: i18nName }}>{i18nName}</Text>
                      </Body14RegularTertiary>
                    </Col>
                    <Col
                      span={10}
                      style={{ textAlign: "right", overflowWrap: "break-word" }}
                    >
                      <Body14RegularPrimary>{value}</Body14RegularPrimary>
                    </Col>
                  </Row>
                );
              })}
            </Flex>
          </AlarmContainer>
        );
      })}
    </>
  );
};

export default Status;
