import { Row, Col } from "antd";
import { BasicModal, commonStyles } from "@datwyler/mfe-shared-components";
import { InfoModalType } from "@types";

const { Body14RegularTertiary, Body14RegularPrimary } = commonStyles;

const InformationModal = ({
  open,
  handleOk,
  handleCancel,
  infoData = {},
  intl,
}: InfoModalType) => {
  return (
    <BasicModal
      title={intl.formatMessage({ id: "information_modal_title" })}
      isOpen={open}
      handleCancel={handleCancel}
      handleOk={handleOk}
      footer={() => {}}
    >
      <Row gutter={[0, 8]}>
        {Object.keys(infoData).map((key) => {
          return (
            <>
              <Col span={12} style={{ textAlign: "left" }}>
                <Body14RegularTertiary>{key}</Body14RegularTertiary>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Body14RegularPrimary>{infoData[key]}</Body14RegularPrimary>
              </Col>
            </>
          );
        })}
      </Row>
    </BasicModal>
  );
};

export default InformationModal;
