/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useContext } from "react";
import { Form, type FormProps, type UploadFile } from "antd";
import {
  ApiContext,
  AddIcon,
  NotificationContext,
  useIntl,
  commonStyles,
} from "@datwyler/mfe-shared-components";

import { type FieldType, AttachmentType } from "@types";
import AttachmentModal from "./Modal";

const { Button16Invert } = commonStyles;

const Attachment = ({
  siteId,
  tenantId,
  showSiteField = false,
  siteOptions = [],
  onChildUpdate = () => {},
}: AttachmentType) => {
  const intl = useIntl();
  const { uri, apiInstance } = useContext<any>(ApiContext);
  const { openNotification } = useContext<any>(NotificationContext);
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [submittable, setSubmittable] = useState<boolean>(false);
  const [filelist, setFilelist] = useState<UploadFile[]>([]);
  const { isFieldsTouched } = form;
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  const handleSubmit: FormProps<FieldType>["onFinish"] = (values) => {
    const { name, description, dragger, siteId: selectedSiteId } = values;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("file", dragger[0]);

    const url =
      uri +
      "/tenants/" +
      tenantId +
      "/sites/" +
      selectedSiteId +
      "/attachments";
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    apiInstance
      .post(url, formData, config)
      .then(() => {
        openNotification({
          type: "success",
          description: intl.formatMessage({ id: "attachment_upload_msg" }),
          placement: "topRight",
        });
        onChildUpdate();
      })
      .catch(() => {
        openNotification({
          type: "error",
          description: intl.formatMessage({ id: "attachment_upload_error" }),
          placement: "topRight",
        });
      });
    setIsOpen(false);
    form.resetFields();
    setFilelist([]);
  };

  return (
    <>
      <Button16Invert
        type="primary"
        block
        icon={<AddIcon style={{ height: 24, width: 24 }} />}
        onClick={() => setIsOpen(true)}
      >
        {intl.formatMessage({ id: "attachment" })}
      </Button16Invert>
      <AttachmentModal
        form={form}
        submittable={submittable && isFieldsTouched()}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleSubmit={handleSubmit}
        filelist={filelist}
        setFilelist={setFilelist}
        showSiteField={showSiteField}
        siteId={siteId}
        siteOptions={siteOptions}
        intl={intl}
      />
    </>
  );
};

export default Attachment;
