import { Flex, Badge, Row, Col, Typography } from "antd";
import { IntlShape } from "react-intl";
import {
  lodash as _,
  moment,
  commonStyles,
} from "@datwyler/mfe-shared-components";

import { getAlarmSeverityIcon } from "@helpers";
import { AlarmType } from "@types";
import { AlarmContainer } from "./styled";

const { Body14RegularTertiary, Body16MediumPrimary, Body14RegularSecondary } =
  commonStyles;
const { Text } = Typography;

const Alarm = ({ alarms, intl }: { alarms: AlarmType[]; intl: IntlShape }) => {
  const alamrsGrouped = _.groupBy(alarms, ({ device: { name } }) => name);

  const keys = Object.keys(alamrsGrouped);
  return (
    <>
      {keys.map((key) => {
        const alarmDetails = alamrsGrouped[key];
        return (
          <AlarmContainer key={key}>
            <Flex vertical gap={12}>
              <Body16MediumPrimary>{key}</Body16MediumPrimary>
              {alarmDetails.map((alarm, index) => {
                const attribute_name = _.get(
                  alarm,
                  "values.attribute_name",
                  ""
                );
                const severity = getAlarmSeverityIcon(
                  _.get(alarm, "severity", "error")
                );
                const timestamp = _.get(alarm, "time", "");
                const date = moment.unix(timestamp).format("MM/DD/YYYY");
                const time = moment.unix(timestamp).format("hh:mm:ss");
                const i18nName = intl.formatMessage({ id: attribute_name });
                return (
                  <Row key={index} wrap={false}>
                    <Col span={10} style={{ textAlign: "left" }}>
                      <Body14RegularSecondary>
                        <Text ellipsis={{ tooltip: i18nName }}>
                          <Badge status={severity} />
                          &nbsp;&nbsp; {i18nName}
                        </Text>
                      </Body14RegularSecondary>
                    </Col>
                    <Col span={6} style={{ textAlign: "center" }}>
                      <Body14RegularTertiary>{time}</Body14RegularTertiary>
                    </Col>
                    <Col span={8} style={{ textAlign: "right" }}>
                      <Body14RegularTertiary>{date}</Body14RegularTertiary>
                    </Col>
                  </Row>
                );
              })}
            </Flex>
          </AlarmContainer>
        );
      })}
    </>
  );
};

export default Alarm;
